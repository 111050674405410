<template>
  <div class="nav-bar">
    <div class="toolbar">
      <div class="logo" @click="navigateToHome()">
        <img src="@/assets/icon/company.png" alt="Company Logo" height="40">
        <span>GrowthSoft</span>
      </div>
      <div class="nav-links" v-if="!isMobile">
        <div class="nav-item" @click="navigateToHome()">
          <span class="main-text">{{ $t('home') }}</span>
          <div class="underline"></div>
        </div>
        <div class="nav-item company-overview" @click="navigateToSection('companyInfo','')" @mouseover="showCompanyOverview = true" @mouseleave="showCompanyOverview = false">
          <span class="main-text">{{ $t('companyInfo') }}</span>
          <div class="underline"></div>
          <transition name="fade">
            <div v-if="showCompanyOverview" class="dropdown-content">
              <ul class="dropdown-list">
                <!-- <li class="dropdown-item" @click.stop="navigateToSection('companyInfo','achievements')">{{ $t('achievementsNavBar') }}</li> -->
                <li class="dropdown-item" @click.stop="navigateToSection('companyInfo','vision')">{{ $t('visionNavBar') }}</li>
                <li class="dropdown-item" @click.stop="navigateToSection('companyInfo','companyprofile')">{{ $t('companyProfileNavBar') }}</li>
                <li class="dropdown-item" @click.stop="navigateToSection('companyInfo','welfare')">{{ $t('welfareNavBar') }}</li>
              </ul>
            </div>
          </transition>
        </div>
        <div class="nav-item dropdown" @click="navigateToSection('solutionInfo','')" @mouseover="showSolutions = true" @mouseleave="showSolutions = false">
          <span class="main-text">{{ $t('solutions') }}</span>
          <div class="underline"></div>
          <transition name="fade">
            <div v-if="showSolutions" class="dropdown-content">
              <ul class="dropdown-list">
                <li class="dropdown-item" @click.stop="navigateToSection('solutionInfo','SES')">{{ $t('ses') }}</li>
                <li class="dropdown-item" @click.stop="navigateToSection('solutionInfo','AppDev')">{{ $t('appDev') }}</li>
                <!-- <li class="dropdown-item" @click.stop="navigateToSection('solutionInfo','Dispatch')">{{ $t('dispatch') }}</li> -->
              </ul>
            </div>
          </transition>
        </div>
        <div class="nav-item" @click="navigateToSection('contactInfo','')">
          <span class="main-text">{{ $t('contact') }}</span>
          <div class="underline"></div>
        </div>
        <div class="nav-item" @click="navigateToSection('recruitmentInfo','')">
          <span class="main-text">{{ $t('recruitmentInfo') }}</span>
          <div class="underline"></div>
        </div>
        <div class="nav-item dropdown" @mouseover="showDropdown = true" @mouseleave="showDropdown = false">
          <span class="main-text">language</span>
          <div class="underline"></div>
          <transition name="fade">
            <div v-if="showDropdown" class="dropdown-content">
              <ul class="dropdown-list">
                <li v-for="(value, key) in languages" :key="key" class="dropdown-item" @click="changeLanguage(key)">
                  {{ value }}
                </li>
              </ul>
            </div>
          </transition>
        </div>
      </div>
      <div class="hamburger-menu" v-if="isMobile" @click="toggleSideNav" :class="{ 'is-active': isSideNavOpen }">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  props: {
    isSideNavOpen: Boolean
  },
  data() {
    return {
      showDropdown: false,
      showCompanyOverview: false,
      showSolutions: false,
      isMobile: false,
      languages: this.$i18n.messages[this.$i18n.locale].languages
    };
  },
  watch: {
    '$i18n.locale'() {
      this.languages = this.$i18n.messages[this.$i18n.locale].languages;
    }
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      sessionStorage.setItem('language', lang);
    },
    navigateToSection(router, subSectionId) {
      const targetPath = `/${router}`;
      const hash = subSectionId ? `#${subSectionId}` : '';
      const currentPath = this.$route.path;
      const currentHash = window.location.hash;

      if (currentPath !== targetPath) {
        this.$router.push(targetPath).then(() => {
          if (subSectionId) {
            location.hash = hash;
          }
        });
      } else if (currentHash !== hash) {
        this.$router.replace({ hash: hash }).then(() => {
          const element = document.getElementById(subSectionId);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        });
      } else if (subSectionId && currentHash === hash) {
        const element = document.getElementById(subSectionId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    },
    navigateToHome() {
      if (this.$route.path !== '/') {
        this.$router.push('/');
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    },
    toggleSideNav() {
      this.$emit('toggleSideNav');
    },
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 768;
    }
  },
  mounted() {
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
    const savedLanguage = sessionStorage.getItem('language');
    if (savedLanguage) {
      this.$i18n.locale = savedLanguage;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile);
  }
};
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

.nav-bar {
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #414c6459;
  z-index: 99;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  position: fixed;
  top: 0;
  left: 0;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.logo {
  height: 40px;
  display: flex;
  cursor: pointer;
  span {
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 8px;
  }
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-item {
  margin-left: 20px;
  cursor: pointer;
  position: relative;
  padding: 10px 15px;
  text-decoration: none;
  color: #414c64;
}

.nav-item:hover .underline {
  width: 26%;
  left: 37%;
}

.main-text {
  font-weight: 300;
  font-size: 16px;
  color: #414c64;
  position: relative;
  z-index: 1;
}

.underline {
  position: absolute;
  bottom: 5px;
  left: 50%;
  width: 0;
  height: 1.5px;
  background-color: #414c64;
  border-radius: 50px;
  transition: width 0.3s ease, left 0.3s ease;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  font-size: 14px;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateY(-10px);
}

.nav-item:hover .dropdown-content {
  display: block;
  opacity: 1;
  transform: translateY(0);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.dropdown-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown-item {
  padding: 8px 16px;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #414c64e0;
  color: #ffffff;
}

.hamburger-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 25px;
  cursor: pointer;
  position: relative;
  z-index: 100;

  span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #414c64;
    transition: all 0.3s ease;
  }

  &.is-active span:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  &.is-active span:nth-child(2) {
    opacity: 0;
  }

  &.is-active span:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }
}
</style>
