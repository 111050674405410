<template>
  <div class="recruit-info" v-show="!loading">
    <!-- Banner -->
    <div class="banner">
      <img :src="preloadedImages.work2" alt="Company Profile" class="banner-img" v-if="preloadedImages.work2">
      <div class="overlay"></div>
      <div class="banner-text">
        <h1 v-html="$t('recruitment.bannerTitle')"></h1>
        <button @click="navigateToSection('RECRUIT')">{{ $t('recruitment.bannerButton') }}</button>
      </div>
    </div>

    <!-- Recruit Section -->
    <div id="RECRUIT" class="section recruit" v-observe-visibility="onVisibilityChange">
      <div class="recruit-content">
        <h2>{{ $t('recruitment.title') }}</h2>
        <p v-html="$t('recruitment.description')"></p>
      </div>
      <!-- Job Sections -->
      <div class="jobs">
        <div v-for="(job, index) in jobs" :key="index" class="job">
          <div class="job-title" @click="toggleJob(index)">
            <span>{{ job.title }}</span>
            <span class="toggle-icon" :class="{ open: activeJobIndex === index }">+</span>
          </div>
          <div :class="['job-details', { 'job-details-expanded': activeJobIndex === index }]">
            <div class="job-detail-item" v-for="(detail, detailIndex) in job.details" :key="detailIndex">
              <p class="job-detail-title">{{ detail.title }}</p>
              <div class="job-detail-content">
                <div v-if="Array.isArray(detail.content)">
                  <div v-for="(item, itemIndex) in detail.content" :key="itemIndex" class="content-item">
                    <div class="label-column">{{ item.label }}</div>
                    <div class="content-column" v-html="item.value"></div>
                  </div>
                </div>
                <div v-else v-html="detail.content"></div>
              </div>
            </div>
            <button class="big-button" @click="applyForJob(job.title)">{{ $t('recruitment.applyButton') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecruitmentInfo',
  data() {
    return {
      activeJobIndex: null,
      jobs: this.getJobs()
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    preloadedImages: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    getJobs() {
      return [
        {
          title: this.$t('recruitment.job1.title'),
          details: [
            {
              title: this.$t('recruitment.job1.details[0].title'),
              content: [
                {
                  label: this.$t('recruitment.job1.details[0].content[0].label'),
                  value: this.$t('recruitment.job1.details[0].content[0].value')
                }
              ]
            },
            {
              title: this.$t('recruitment.job1.details[1].title'),
              content: [
                {
                  label: this.$t('recruitment.job1.details[1].content[0].label'),
                  value: this.$t('recruitment.job1.details[1].content[0].value')
                },
                {
                  label: this.$t('recruitment.job1.details[1].content[1].label'),
                  value: this.$t('recruitment.job1.details[1].content[1].value')
                }
              ]
            },
            {
              title: this.$t('recruitment.job1.details[2].title'),
              content: this.$t('recruitment.job1.details[2].content')
            }
          ]
        },
        {
          title: this.$t('recruitment.job2.title'),
          details: [
            {
              title: this.$t('recruitment.job2.details[0].title'),
              content: this.$t('recruitment.job2.details[0].content')
            },
            {
              title: this.$t('recruitment.job2.details[1].title'),
              content: [
                {
                  label: this.$t('recruitment.job2.details[1].content[0].label'),
                  value: this.$t('recruitment.job2.details[1].content[0].value')
                },
                {
                  label: this.$t('recruitment.job2.details[1].content[1].label'),
                  value: this.$t('recruitment.job2.details[1].content[1].value')
                }
              ]
            },
            {
              title: this.$t('recruitment.job2.details[2].title'),
              content: this.$t('recruitment.job2.details[2].content')
            }
          ]
        },
        // {
        //   title: this.$t('recruitment.job3.title'),
        //   details: [
        //     {
        //       title: this.$t('recruitment.job3.details[0].title'),
        //       content: [
        //         {
        //           label: this.$t('recruitment.job3.details[0].content[0].label'),
        //           value: this.$t('recruitment.job3.details[0].content[0].value')
        //         }
        //       ]
        //     },
        //     {
        //       title: this.$t('recruitment.job3.details[1].title'),
        //       content: [
        //         {
        //           label: this.$t('recruitment.job3.details[1].content[0].label'),
        //           value: this.$t('recruitment.job3.details[1].content[0].value')
        //         },
        //         {
        //           label: this.$t('recruitment.job3.details[1].content[1].label'),
        //           value: this.$t('recruitment.job3.details[1].content[1].value')
        //         }
        //       ]
        //     },
        //     {
        //       title: this.$t('recruitment.job3.details[2].title'),
        //       content: this.$t('recruitment.job3.details[2].content')
        //     }
        //   ]
        // }
      ];
    },
    toggleJob(index) {
      this.activeJobIndex = this.activeJobIndex === index ? null : index;
    },
    navigateToSection(sectionId) {
      location.hash = `#${sectionId}`;
      location.hash = '';
    },
    onVisibilityChange(isVisible, entry) {
      if (isVisible) {
        entry.target.classList.add('visible');
      } else {
        entry.target.classList.remove('visible');
      }
    },
    applyForJob(jobTitle) {
      let inquiryType = '';
      if (jobTitle === this.$t('recruitment.job1.title')) {
        inquiryType = this.$t('recruitment.newGraduate');
      } else {
        inquiryType = this.$t('recruitment.careerRecruitment');
      }
      this.$router.push({ path: '/contactInfo#contact', query: { inquiryType } });
    }
  },
  watch: {
    '$i18n.locale': function () {
      this.jobs = this.getJobs();
    }
  },
  directives: {
    'observe-visibility': {
      inserted(el, binding) {
        const options = {
          threshold: 0.1 // Adjust as needed
        };
        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (binding.value && typeof binding.value === 'function') {
              binding.value(entry.isIntersecting, entry);
            }
          });
        }, options);
        observer.observe(el);
      }
    }
  }
};
</script>


<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

.recruit-info {
  text-align: center;
  font-family: Arial, sans-serif;
  overflow-x: hidden; /* 禁止横向滚动 */

  .banner {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin-bottom: 20px;

    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }

    .banner-text {
      margin-top: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      text-align: center;
      
      h1 {
        font-size: 40px;
        letter-spacing: 2px;
        font-weight: 400;
        font-family: 'Noto Sans JP', sans-serif;
      }

      button {
        margin-top: 30px;
        width: 200px;
        height: 74px;
        font-size: 18px;
        letter-spacing: 2px;
        font-weight: 400;
        font-family: 'Noto Sans JP', sans-serif;
        color: #fff;
        background-color: #414c6408;
        border: 1px #fff solid;
        border-radius: 1px;
        transition: background-color 0.3s ease, color 0.3s ease;
      }

      button:hover {
        color: #414c64;
        background-color: #fff;
        border: 1px #414c64 solid;
        border-radius: 1px;
        transition: background-color 0.3s ease, color 0.3s ease;
      }
    }
  }

  .section {
    width: 60%;
    margin: 20px auto 300px auto;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease, transform 0.6s ease;

    &.visible {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .recruit {
    .recruit-content {
      font-family: 'Noto Sans JP', sans-serif;
      padding: 60px;

      h2 {
        font-size: 40px;
        font-weight: 400;
        color: #293244;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        color: #414c64;
      }
    }
  }

  .jobs {
    width: 84%;
    margin: 0 auto 200px auto;

    .job {
      border-bottom: 1px solid #ccc;
      padding: 25px 0;
      font-family: 'Noto Sans JP', sans-serif;

      .job-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        letter-spacing: 0.5px;
        font-size: 20px;
        font-weight: 400;
        color: #293244;
        width: 100%;
        padding: 10px;

        .toggle-icon {
          font-size: 24px;
          transition: transform 0.4s ease;

          &.open {
            transform: rotate(45deg);
          }
        }
      }

      .job-details {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        font-size: 16px;
        line-height: 1.5;
        padding: 0 53px;
        color: #414c64;
        overflow: hidden; /* 确保动画生效 */
        max-height: 0;
        opacity: 0;
        transition: max-height 0.3s ease, opacity 0.3s ease;
        text-align: left;

        .job-detail-item {
          margin-bottom: 10px;

          .job-detail-title {
            color: #293244;
            font-weight: 600;
            margin-bottom: 15px;
          }

          .job-detail-content {
            display: flex;
            flex-wrap: wrap;
            font-size: 15px;
            padding-left: 32px;
            line-height: 1.8;

            .content-item {
              margin-bottom: 20px;
              width: 100%;

              .label-column {
                margin-bottom: 9px;
                color: #414c64;
                font-weight: 600;
              }

              .content-column {
                padding-left: 28px;
                flex-grow: 1;
              }
            }
          }
        }
      }

      .job-details-expanded {
        max-height: 2000px;
        opacity: 1;
        text-align: left;
      }

      /* 新增大按钮样式 */
      .big-button {
        font-family: 'Noto Sans JP', sans-serif;
        margin-top: 20px;
        padding: 15px 30px;
        margin-bottom: 30px;
        font-size: 20px;
        font-weight: 400;
        color: #414c64;
        background-color: #fff;
        border: 1px #414c64 solid;
        border-radius: 2px;
        cursor: pointer;
        transition: background-color 0.6s ease, transform 0.6s ease;
      }

      .big-button:hover {
        color: #fff;
        background-color: #414c64;
      }
    }
  }
}

@media (max-width: 768px) {
  .recruit-info {
    overflow-x: hidden; /* 禁止横向滚动 */

    .banner {
      height: 100vh;

      .banner-text {
        h1 {
          font-size: 24px;
        }

        button {
          width: 160px;
          height: 50px;
          font-size: 14px;
        }
      }
    }

    .section {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;

      .recruit-content {
        padding: 10px;

        h2 {
          font-size: 24px;
        }
        
        p {
          font-size: 14px;
          text-align: left;
        }
      }

      .jobs {
        width: 94%;

        .job {
          padding: 15px 0;

          .job-title {
            font-size: 18px;
            padding: 0;

            .toggle-icon {
              padding-right: 2%;
            }
          }

          .job-details {
            padding: 0;
    
            .job-detail-item {
              padding: 0 20px;
    
              .job-detail-content {
                padding: 0 20px;
    
                .content-item {
    
                  .content-column {
                    font-size: 14px;
                  }
                }
              }
            }
          }

          .big-button {
            width: 100%;
            padding: 10px;
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>
